@mixin hide-scrollbar {
	// Edge
	-ms-overflow-style: none;

	// Firefox
	scrollbar-width: none;

	// Webkit
	&::-webkit-scrollbar {
		display: none;
	}
}
