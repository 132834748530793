@use '../../../style/media';

.item {
	position: relative;
	flex: 0 0 100%;
	aspect-ratio: 16 / 9;
	overflow: hidden;
	scroll-snap-align: center;

	// Align item snap center to wrap center
	$padding-left: var(--wrap-padding-left);
	$padding-right: var(--wrap-padding-right);
	scroll-margin-left: calc($padding-left - $padding-right);

	@include media.below-m {
		// XS–S and M-XL stretch on their own. S–M needs some help.
		flex-basis: calc(100vw - $padding-left - $padding-right);
	}
}

.thumbnail {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;

	// Unset button styles
	appearance: none;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;

	&:empty {
		// Fallback gradient for when image isn't loaded
		background-image: linear-gradient(
			90deg,
			#62dfe8 0% 12.5%,
			#e7f1f4 12.5% 25%,
			#ff3d00 25% 37.5%,
			#f3fc77 37.5% 50%,
			#00afd6 50% 62.5%,
			#433531 62.5% 75%,
			#ce1200 75% 87.5%,
			#c5ed00 87.5% 100%
		);
	}

	&:focus {
		outline: none;
	}
}

.image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.icon {
	position: absolute;

	bottom: 2rem;
	right: 2rem;
	width: 4rem;
	height: 4rem;

	filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
	opacity: 0.9;

	transition: 0.1s ease-out;
	transition-property: transform, filter;

	.thumbnail:hover & {
		transform: scale(1.1);
		filter: drop-shadow(0 0 0.55rem rgba(0, 0, 0, 0.5));
	}
}
