:root {
	box-sizing: border-box;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
dl,
dt,
dd,
menu {
	margin: 0;
	padding: 0;
	font-weight: inherit;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	color: var(--color-accent);
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}
