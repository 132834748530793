.embed {
	width: 100%;
	aspect-ratio: 16/9;
	background-color: #000;
}

.dev {
	// Fallback gradient for development
	background-image: linear-gradient(
		90deg,
		#62dfe8 0% 12.5%,
		#e7f1f4 12.5% 25%,
		#ff3d00 25% 37.5%,
		#f3fc77 37.5% 50%,
		#00afd6 50% 62.5%,
		#433531 62.5% 75%,
		#ce1200 75% 87.5%,
		#c5ed00 87.5% 100%
	);
}
