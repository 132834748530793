@use '../../style/media';

.subsection {
	h1 + & {
		margin-top: -8rem;
	}

	& + & {
		margin-top: 12rem;
	}
}

.group {
	display: grid;
	grid-auto-columns: 1fr;
	grid-auto-rows: 1fr;
	gap: 4rem;

	@include media.l {
		grid-template-columns: repeat(3, 1fr);
		margin-left: 6rem;
		margin-right: 6rem;
	}
}

.noMobile {
	@include media.below-l {
		display: none;
	}
}
