@use '../../../style/media';

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 8rem 4rem 6rem;
	color: #fff;
	background-color: #132029;
	text-align: center;

	&:nth-child(2n) {
		background-color: #295d7a;
	}
}

.type {
	text-transform: uppercase;
	font-size: 3rem;
	font-weight: 900;
	margin-bottom: 1rem;
}

.duration {
	text-transform: uppercase;
	font-size: 2rem;
	color: #c5c6c8;
}

.price {
	margin: 4rem 0;
	font-size: 3.5rem;
	line-height: 1.4;

	.type + & {
		// No duration
		padding-top: 2rem;
	}

	strong {
		display: inline-block;
		font-weight: 400;
		font-size: 6rem;
		vertical-align: top;
		margin-top: -0.5rem;
	}

	small {
		font-size: 3rem;
		vertical-align: middle;
	}
}

.details {
	margin-left: 4rem;
	margin-bottom: 8rem;
	list-style: disc outside;
	text-align: left;
	font-size: 3rem;
	line-height: 1.4;

	@include media.l {
		font-size: 2.5rem;
	}

	@include media.below-l {
		display: none;
	}


	li + li {
		margin-top: 2rem;
	}
}

.action {
	align-self: stretch;
	margin: auto 2rem 0;

	a {
		display: block;
		width: 100%;
		padding: 2rem;
		border-radius: 7rem;
		border: none;
		background: #fff;
		color: #132029;
		font: inherit;
		font-weight: 900;
		font-size: 2.5rem;
		transition: transform 0.1s ease-in-out;

		&:hover {
			text-decoration: none;
			transform: scale(1.05);
		}

		&:active {
			transform: scale(1.025);
		}
	}
}
