@use '../../style/media';

.content {
	display: flex;
	align-items: center;
	gap: 8rem 16rem;

	@include media.below-l {
		flex-direction: column;
	}
}

.text {
	flex: 1 1 100%;
}

.image {
	flex: 1 1 auto;

	img {
		display: block;
		width: auto;
		height: auto;
	}
}
