@use '../../style/media';

.content {
	display: flex;
	max-width: 120rem;
	margin-left: auto;
	margin-right: auto;
	padding: 4rem;
	gap: 4rem 8rem;

	color: #fff;
	background-color: var(--color-background);

	@include media.below-m {
		flex-direction: column;
		padding-top: 0;
	}
}

.contacts {
	flex-basis: calc(50% - 4rem);
}

.map {
	flex-basis: calc(50% + 4rem);
}

.subtitle {
	font-size: 5rem;
	font-weight: 900;
	margin: 6rem 0;

	@include media.m {
		margin-bottom: 8rem;
		margin-left: 2rem;
	}
}

.contact {
	display: flex;
	align-items: center;
	gap: 6rem;
	margin: 4rem 2rem;

	& + & {
		margin-top: 4rem;
	}

	dt {
		flex-shrink: 0;
		width: 3rem;
		aspect-ratio: 1;

		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	dd {
		line-height: 1.4;

		a {
			color: inherit;
		}
	}
}
