.title {
	font-size: 4rem;
	font-weight: 900;
	text-transform: lowercase;
	margin-bottom: 12rem;
}

.inner {
	display: inline-block;
	padding-right: 1rem;
	padding-bottom: 2rem;
	color: var(--section-title-color);
	border-bottom: 1px solid var(--section-title-border-color, currentColor);

	&::after {
		content: '';
		display: block;
		border-bottom: inherit;

		position: absolute;
		width: calc(50% - (var(--wrap-width) / 2));
		min-width: var(--wrap-padding);
		margin-top: 2rem;
		left: 0;
	}
}

.title.right {
	text-align: right;

	.inner {
		padding-right: 0;
		padding-left: 1rem;

		&::after {
			left: auto;
			right: 0;
		}
	}
}
