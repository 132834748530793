@use '../../style/media';

.wrap,
.wrapWide,
.wrapNarrow {
	margin-left: auto;
	margin-right: auto;
}

.wrap,
.wrapNarrow {
	padding-left: var(--wrap-padding-left);
	padding-right: var(--wrap-padding-right);

	@include media.m {
		max-width: calc(
			var(--wrap-width) + var(--wrap-padding-left) + var(--wrap-padding-right)
		);
	}
}

.wrapWide {
	padding-left: var(--wrap-padding);
	padding-right: var(--wrap-padding);
}

.wrapNarrow {
	--wrap-width: 120rem;
}
