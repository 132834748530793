$s: 420px;
$m: 828px;
$l: 1024px;
$xl: 1400px;
$xxl: 1920px;

@mixin below-s {
	@media (max-width: $s - 1px) {
		@content;
	}
}

@mixin s {
	@media (min-width: $s) {
		@content;
	}
}

@mixin below-m {
	@media (max-width: $m - 1px) {
		@content;
	}
}

@mixin m {
	@media (min-width: $m) {
		@content;
	}
}

@mixin below-l {
	@media (max-width: $l - 1px) {
		@content;
	}
}

@mixin l {
	@media (min-width: $l) {
		@content;
	}
}

@mixin below-xl {
	@media (max-width: $xl - 1px) {
		@content;
	}
}

@mixin xl {
	@media (min-width: $xl) {
		@content;
	}
}

@mixin xxl {
	@media (min-width: $xxl) {
		@content;
	}
}
