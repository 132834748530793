@use '../../style/media';

.intro {
	display: flex;
	place-content: center;
	place-items: center;

	height: 100vh;
	padding-top: 48rem;
	@supports (max-height: -webkit-fill-available) {
		max-height: max(100vh, -webkit-fill-available);
	}

	background: url('./cover.jpg') center center / cover no-repeat;
}

.content {
	--wrap-width: 140rem;

	@include media.below-m {
		max-width: 140rem;
	}

	abbr {
		pointer-events: none;
		text-decoration: none;
		border-bottom: none;
		font-variant: normal;
	}

	em {
		font-style: normal;
		position: relative;
		z-index: 0;

		&::after {
			content: '';
			position: absolute;
			left: -2px;
			right: -2px;
			bottom: 0.1em;
			border-bottom: 0.2em solid var(--color-accent);
			z-index: -1;
		}
	}
}

.title {
	font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	font-size: 9rem;
	text-transform: uppercase;
	margin-bottom: 3rem;

	@include media.below-m {
		font-size: 8rem;
	}

	@include media.below-s {
		font-size: 6rem;
	}
}

.subtitle {
	max-width: 90rem;
	font-weight: 900;
	line-height: 1.2;
	margin-bottom: 6rem;
}

.links {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;

	@include media.below-s {
		flex-direction: column;
		text-align: center;
	}
}

.link {
	display: block;
	color: inherit;
	text-decoration: none;

	font-size: 3rem;
	font-weight: 900;
	padding: 3rem 6rem;
	border-radius: 8rem;

	&:hover {
		text-decoration: inherit;
	}

	&.primary {
		background-color: var(--color-accent);
	}

	&.secondary {
		$border-width: 0.5rem;
		border: $border-width solid var(--color-accent);
		padding: (3rem - $border-width) (6rem - $border-width);
	}
}
