.footer {
	display: flex;
	flex-wrap: wrap;
	column-gap: 4rem;
	row-gap: 2rem;

	padding-top: 8rem;
	padding-bottom: 8rem;

	font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	font-size: 3em;
	line-height: 1.4;
	text-transform: lowercase;
}

.copyright {
	margin-right: auto;
	white-space: nowrap;
}

.links {
	display: flex;
	align-items: center;
	gap: 2rem 4rem;

	a {
		color: inherit;
		transition: 0.1s ease-out color;

		&:hover {
			text-decoration: none;

			@media (any-hover: hover) {
				color: var(--color-accent);

				img {
					filter: invert(35%) sepia(42%) saturate(5492%) hue-rotate(2deg) brightness(95%) contrast(102%);
				}
			}
		}
	}

	img {
		display: block;
		width: 4rem;
		height: 4rem;
		filter: invert(1);
	}
}
