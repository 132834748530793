.text {
	font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
	font-size: 2.5rem;
	line-height: 1.4;
	text-align: justify;

	& + & {
		margin-top: 4rem;
	}

	strong {
		font-weight: 900;
	}
}
