@use '../../style/media';
@use '../../lib/hide-scrollbar' as *;

// Align item center to wrap center
$width: var(--wrap-width);
$padding-left: var(--wrap-padding-left);
$padding-right: var(--wrap-padding-right);

$offset-padding-left: calc(
	(100vw - $width + $padding-left - $padding-right) / 2
);

$offset-padding-right: calc(
	(100vw - $width - $padding-left + $padding-right) / 2
);

$slider-padding-left: max($offset-padding-left, $padding-left);
$slider-padding-right: max($offset-padding-right, $padding-right);

.sliderRoot {
	--wrap-padding-left-inner: calc(var(--wrap-padding-left) * 3);
	--wrap-padding-right-inner: calc(var(--wrap-padding-right) * 3);

	@include media.below-m {
		--wrap-padding-left-inner: calc(var(--wrap-padding-left) * 2);
		--wrap-padding-right-inner: calc(var(--wrap-padding-right) * 2);
	}
}

.sliderContainer {
		--wrap-padding-left: calc(var(--wrap-padding-left-inner));
	--wrap-padding-right: calc(var(--wrap-padding-right-inner));


	position: relative;

	.button {
		position: absolute;
		top: 0;
		bottom: 0;
	}

	.buttonPrev {
		left: 0;
		width: $slider-padding-left;
	}

	.buttonNext {
		right: 0;
		width: $slider-padding-right;
	}
}


.slider {
	display: flex;
	position: relative;
	width: 100%;
	overflow: auto;
	overscroll-behavior-x: contain;
	touch-action: pan-x;

	scroll-snap-type: x mandatory;
	scroll-snap-stop: always;

	padding-left: $slider-padding-left;
	padding-right: $slider-padding-right;

	@include hide-scrollbar;
}

.itemClone {
	position: absolute !important;
	height: 100%;
	pointer-events: none;

	button {
		width: auto;
		aspect-ratio: 16 / 9;
	}
}

.itemCloneLeft {
	transform: translateX(-100%);
	width: $slider-padding-left;

	button {
		left: auto;
	}
}

.itemCloneRight {
	transform: translateX(100%);
	width: $slider-padding-right;

	button {
		right: auto;
	}
}

.button {
	background: none center center / 100% 100% no-repeat;
	padding: 0;
	border: none;
	outline: none;
	font: 0/0 none;
	cursor: pointer;
}

.menu {
	display: flex;
	margin-top: 4rem;

	.button {
		height: 2.5rem;
		width: 2.5rem;
		min-height: 40px;
		min-width: 40px;
		transition: transform 0.1s ease-out;
	}

	.buttonPrev {
		margin-right: auto;
		background-image: url('./icons/prev.svg');

		&:hover {
			transform: translateX(-10%);
		}

		&:active {
			transform: translateX(-5%) scale(0.95);
		}
	}

	.buttonNext {
		margin-left: auto;
		background-image: url('./icons/next.svg');

		&:hover {
			transform: translateX(10%);
		}

		&:active {
			transform: translateX(5%) scale(0.95);
		}
	}
}
