@use './section-title/style.scss' as title;

.section {
	position: relative;
	font-size: 3rem;
	padding-top: 8rem;
	padding-bottom: 16rem;

	--section-title-color: var(--color-accent);
	--section-title-border-color: #fff;
}

.section.white {
	background-color: #d8e2e7;
	color: #000;

	--section-title-border-color: #000;
}

.background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	pointer-events: none;

	background-attachment: fixed;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	opacity: 0.3;
	mix-blend-mode: multiply;

	@supports (-webkit-touch-callout: none) {
		// iOS doesn't really support fixed cover-size fixed backgrounds
		background-attachment: scroll;
	}
}
