@use 'media';

:root {
	min-height: 100vh;
	min-width: 320px;

	font-family: 'Nexa', 'Verdana', sans-serif;
	font-weight: 400;
	font-size: 5px;
	line-height: 1;

	background-color: var(--color-background);
	color-scheme: dark;
	color: #fff;

	--wrap-width: 160rem;
	--wrap-padding: 8rem;

	--wrap-padding-left: var(--wrap-padding);
	--wrap-padding-right: var(--wrap-padding);

	--color-accent: #f24400;
	--color-background: #295d7a;

	@include media.m {
		font-size: 6px;
	}

	@include media.l {
		font-size: 7px;
	}

	@include media.xl {
		font-size: 8px;
	}

	@include media.xxl {
		font-size: 10px;
	}
}
