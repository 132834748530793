@use 'sass:math';

.subtitle {
	$offset: 2rem;
	$line-height: 1.2em;
	$padding-bottom: math.div($offset, math.div($line-height, 1em));

	position: relative;
	font-size: 4rem;
	font-weight: 900;
	line-height: $line-height;
	hyphens: auto;
	margin-bottom: 1em;

	h1 + & {
		margin-top: -8rem;
	}

	&.bottom,
	&.corner {
		z-index: 0;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: var(--color-accent);
		}
	}

	&.bottom {
		&::after {
			position: static;
			margin-top: 0.5em;
			margin-bottom: -0.25em;
			height: 0.5rem;
			max-width: 18rem;
		}
	}

	&.corner {
		$extra-offset: 1rem;
		padding-left: $offset + $extra-offset;
		padding-bottom: $padding-bottom + $extra-offset;

		&::before {
			height: calc($line-height + $offset + $extra-offset);
			width: 0.75rem;
		}

		&::after {
			height: 0.75rem;
			width: calc($line-height + $offset + $extra-offset);
		}
	}
}
