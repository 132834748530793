.modal {
	position: fixed;
	inset: 0;
	z-index: 10;

	display: grid;
	place-content: stretch;
	place-items: center;

	background-color: rgba(0, 0, 0, 0.9);
	touch-action: none;
	overscroll-behavior: none;
}

.close {
	position: absolute;
	top: 4rem;
	left: 4rem;
	z-index: 1;

	appearance: none;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	transition: transform 0.1s ease-out;

	&:hover {
		transform: scale(1.1);
	}

	img {
		filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5));
		display: block;
		width: 4rem;
		height: 4rem;
	}
}
