@use '../../style/media';

.content {
	display: flex;
	flex-direction: column;
	gap: 8rem;

	@include media.m {
		flex-direction: row-reverse;
		align-items: center;
	}
}

.images {
	display: flex;
	column-gap: 4rem;
	flex-basis: 60%;
}

.image {
	display: block;

	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}

.text {
	flex-basis: 40%;
}
