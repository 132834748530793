@use '../../style/media';

.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;

	pointer-events: none;
	a {
		pointer-events: auto;
	}
}

.wrap {
	@include media.below-m {
		padding-left: var(--wrap-padding);
		padding-right: var(--wrap-padding);
	}

	@include media.below-s {
		padding-left: calc(var(--wrap-padding) / 2);
		padding-right: calc(var(--wrap-padding) / 2);
	}
}

.nav {
	display: flex;
	align-items: center;
	min-height: 16rem;
}

.logoContainer {
	flex: 0 0 auto;
	width: 16rem;
	margin-right: var(--wrap-padding);

	@include media.below-m {
		width: 12rem;
		margin-right: calc(var(--wrap-padding) * 0.75);
	}

	@include media.below-s {
		margin-right: calc(var(--wrap-padding) / 2);
	}
}

.logo {
	$logo-width: 345;
	$logo-height: 320;

	display: block;
	aspect-ratio: #{$logo-width} / #{$logo-height};

	background-image: url('./logo.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;

	font-size: 0;
	color: transparent;
}

.links {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem 4rem;

	margin: 0 0 0 auto;
	list-style: none;

	font-size: 3rem;
	font-weight: 900;
	text-transform: lowercase;

	@include media.below-m {
		font-size: 4rem;
		column-gap: 2rem;
		letter-spacing: -0.1rem;
	}

	@include media.m {
		justify-content: space-between;
	}

	@include media.l {
		column-gap: 6rem;
	}

	@include media.xl {
		column-gap: 8rem;
	}

	a {
		color: inherit;
		transition: 0.1s ease-out color;

		&:hover {
			text-decoration: none;

			@media (any-hover: hover) {
				color: var(--color-accent);
			}
		}
	}
}
