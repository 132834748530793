.section {
	padding-top: 12rem;
}

.intro {
	margin-bottom: 8rem;
}

.text {
	text-align: left;

	a {
		font-weight: 900;
	}
}
